<template>
  <section class="m-setting-security m-scroll">
    <!-- 头部导航 -->
    <m-header title="账户安全"></m-header>

    <div class="main">
      <div @click="changePassword" class="setting-cell van-hairline--bottom">
        <div class="label">修改登录密码</div>
        <div class="right">
          <img
            class="back"
            src="@/assets/public/icon-fanhui@2x.png"
            alt="icon"
          />
        </div>
      </div>

      <div @click="handlePhone" class="setting-cell van-hairline--bottom">
        <div class="label">手机号码</div>
        <div class="right">
          <p v-if="phone != ''" class="bind-item">{{ phone }}</p>
          <p v-else class="unbind-item">未绑定</p>
          <img
            class="back"
            src="@/assets/public/icon-fanhui@2x.png"
            alt="icon"
          />
        </div>
      </div>

      <div @click="handleEmail" class="setting-cell">
        <div class="label">邮箱</div>
        <div class="right">
          <p v-if="email != ''" class="bind-item">{{ email }}</p>
          <p v-else class="unbind-item">未绑定</p>
          <img
            class="back"
            src="@/assets/public/icon-fanhui@2x.png"
            alt="icon"
          />
        </div>
      </div>
    </div>

    <div class="main">
      <router-link to class="setting-cell van-hairline--bottom">
        <div class="label">
          <img
            class="label-icon"
            src="@/assets/setting/icon_weiin@2x.png"
            alt="wechat"
          />
          <span>Wechat</span>
        </div>
        <div class="right">
          <p v-if="account.is_wx == 1" class="bind-item">已绑定</p>
          <p v-else class="unbind-item">未绑定</p>
          <img
            class="back"
            src="@/assets/public/icon-fanhui@2x.png"
            alt="icon"
          />
        </div>
      </router-link>

      <router-link to class="setting-cell van-hairline--bottom">
        <div class="label">
          <img
            class="label-icon"
            src="@/assets/setting/icon_face@2x.png"
            alt="FB"
          />
          <span>FaceBook</span>
        </div>
        <div class="right">
          <p v-if="account.is_faceid == 1" class="bind-item">已绑定</p>
          <p v-else class="unbind-item">未绑定</p>
          <img
            class="back"
            src="@/assets/public/icon-fanhui@2x.png"
            alt="icon"
          />
        </div>
      </router-link>

      <router-link to class="setting-cell">
        <div class="label">
          <img
            class="label-icon"
            src="@/assets/setting/icon_ins@2x.png"
            alt="wechat"
          />
          <span>Instagram</span>
        </div>
        <div class="right">
          <p v-if="account.is_instaid == 1" class="bind-item">已绑定</p>
          <p v-else class="unbind-item">未绑定</p>
          <img
            class="back"
            src="@/assets/public/icon-fanhui@2x.png"
            alt="icon"
          />
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import MHeader from "@/components/zh/m-header.vue";
import { userBind, getAccountInfo } from "@/api/zh/mine.js";
export default {
  name: "Security",
  components: { MHeader },
  data() {
    return {
      account: {
        is_wx: 0,
        is_faceid: 0,
        is_instaid: 0,
      },
      phone: "",
      email: "",
    };
  },
  methods: {
    // 退出当前账号
    handleSignOut() {
      this.$router.push({ path: "/zh/login" });
    },

    // 点击手机
    handlePhone() {
      if (this.phone != "" || this.email != "") {
        this.$router.push({
          path: "/zh/setting/phone",
          query: { phone: this.phone, email: this.email },
        });
      } else {
        this.$router.push({path: "/zh/setting/phone"})
      }
    },

    // 点击邮箱
    handleEmail() {
      if (this.phone != "" || this.email != "") {
        if (this.email != "") {
           this.$router.push({path: "/zh/setting/email",query: { phone: this.phone, email: this.email }});
        } else {
          this.$router.push({path: "/zh/setting/bindemail"});
        }
      } else {
        this.$router.push({path: "/zh/setting/bindemail"});
      }
    },

    // 获取用户手机邮箱信息
    getAccount() {
      getAccountInfo().then((res) => {
        if (res) {
          this.phone = res.data.phone;
          this.email = res.data.email;
        }
      });
    },

    // 获取绑定信息
    getBind() {
      userBind().then((res) => {
        if (res) {
          this.account = res.data;
        }
      });
    },

    changePassword() {
      if (this.phone != "" || this.email != "") {
        this.$router.push({
          path: "/zh/setting/identity",
          query: { phone: this.phone, email: this.email },
        });
      } else {
        this.$notify({ type: "warning", message: "请先绑定手机或者邮箱" });
        return false;
      }
    },
  },

  created() {
    this.getAccount();
    this.getBind();
  },
};
</script>

<style lang="less" scoped>
@import "./security.less";
</style>